.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(14, 68, 34);
}

.App-header {
  background-color: rgb(14, 68, 34);
  height: 15vh;
  width: 100vw;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 1em;
  padding-bottom: 1em;
}

.socialIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social-icon{
  padding: 0.5em;
  color: white
}

.social-icon:hover{
  color: rgb(67, 119, 84)
}

.main-container {
  background-color: rgb(225, 239, 229);
  display:flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-me{
  width: 80%;
  text-align: center;
  margin: 0em;
  padding: 0em
}

.language-icons-container {
  display:flex;
  flex-direction: row;
}

.language-icon {
  padding: 1em
}

a {
  color: rgb(26, 94, 52);
  text-decoration: none;
}

a:visited {
  color: rgb(26, 94, 52);
  text-decoration: none;
}

a:hover {
  color: rgb(255, 252, 252);
  text-decoration: none;
}

img {
  padding: 1em
}

.projects-container {
  width: 80%;
  margin: auto;
}


.projects-slider {
  display: flex;
  border-radius: 6px;
  color: rgb(14, 68, 34);
  background: #eee;
  padding: 1rem;
  box-shadow: 0 0 1rem rgb(14, 68, 34) inset;
  overflow-x: auto;
  position: relative;
}
  
.projects-slider::-webkit-scrollbar {
    display: none;
  }


.project-card {
    flex: 1 0 450px;
    box-shadow: 0 1px 1rem -4px rgb(14, 68, 34);
    background: #fff;
    margin: 1rem;
    overflow: hidden;
    border-radius: 6px;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}
    
.project-card > img {
      width: 450px;
      height: 300px;
      object-fit: center;
    }
    
.project-card:hover {
      box-shadow: 0 4px 1rem -4px rgb(14, 68, 34);
      transform: translateY(-10px);
    }

.project-links:hover {
  color: rgb(14, 68, 34);
}
  
  .card-content {
    padding: 1rem;
  }
  
  @media screen and (max-width: 625px) {
    .projects-container {
      width: 100%
    }
    
    .projects-slider {
      padding: .5rem;
    }
    
    .project-card {
      margin: .5rem;
    }
  }
  
  .email-form-container {
    display: flex;
    flex-direction: column;
    margin: 5%;
    align-items: center;
    justify-content: center;
  }

  .email-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .email-form-input {
    padding: 2%;
    margin: 3%;
    width: 40vw;
    font-family: 'JetBrains Mono', monospace;
    text-align: left;
  }

  .email-form-message {
    padding: 2%;
    margin: 3%;
    height: 15vh;
    width: 40vw;
    font-family: 'JetBrains Mono', monospace;
    overflow-y: scroll;
    text-align: left;
  }

  .email-button {
    font-family: 'JetBrains Mono', monospace;
    color: white;
    background-color: rgb(14, 68, 34);
  }

  .email-button:hover{
    color: rgb(14, 68, 34);
    background-color: white;
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: rgb(225, 239, 229);
    color:rgb(14, 68, 34);
    font-family: 'JetBrains Mono', monospace;
    font-size: 20px;
  }

